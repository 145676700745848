<template lang="pug">
BasePane
  Accordion(fix)
    InputElementDropdown.mt-3(
      :items="inputs"
      v-model="inputValue"
      @manage="manageFields"
      @create="createNewInput"
    )
    InputCommonFields
      template(v-if="hasOptions" #afterPlaceholder)
        InputElementOptions
  hr.mt-4.mb-3
  Font(fontSizeProperty="$device.fontSize" editMobile)
    template(#colors)
      OmColorInput(
        :label="$t('fontColor')"
        property="desktop.color"
        typeOfComponent="inputs"
        layout="col"
        dsAllowed
        :editMobile="false"
      )
  hr.mt-1.mb-4
  Accordion(fix)
    template(#title)
      span {{ $t('sizeAndSpacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Dropdown#width.mt-3(
      layout="col"
      :label="$t('width')"
      :items="widthOptions"
      v-model="widthType"
    )
    RangeInput(v-if="widthType === 'manual'" label="" v-model.number="width" :min="30" :max="600")
    Align(
      v-if="['manual', 'fluid'].includes(widthType) || (mobilePreview && widthType !== '100w' && widthType !== null)"
      v-model="align"
    )
    RangeInput(
      v-if="!mobilePreview || widthType"
      :label="$t('height')"
      :min="30"
      :max="300"
      v-model.number="height"
    )
    Margin
  hr.mt-4.mb-4
  BackgroundAndBorder(typeOfComponent="inputs" shadow :deviceSelector="false")
    template(#color)
      OmColorInput(
        :label="$t('background')"
        property="desktop.background.color"
        typeOfComponent="inputs"
        layout="col"
        dsAllowed
        :editMobile="false"
      )
  hr.mt-1.mb-3
  template(#advancedContent)
    Heading(v-if="isEmailAndHasBlockEmailFlag" label :open="allowBlock") {{ $t('onlyNewLead') }}
      template(#prependEnding)
        OmSwitch#required(v-model="allowBlock")
      template(#expandableContent)
        OmInput#allow-block-error-message(
          small
          v-model="allowBlockErrorMessage"
          :label="$t('errorMessage')"
        )
    OmButton(
      v-if="isEmailAndHasBlockEmailFlag"
      secondary
      block
      icon="envelope-lock"
      @click="inputValidation()"
    ) {{ $t('emailInputValidations') }}
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>
<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import InputElementDropdown from '@/components/Editor/InputElementDropdown/InputElementDropdown.vue';
  import InputCommonFields from '@/components/Editor/InputCommonFields/InputCommonFields.vue';
  import InputElementOptions from '@/components/Editor/InputElementOptions/InputElementOptions.vue';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Font from '@/components/Editor/Blocks/Font.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      InputElementDropdown,
      InputCommonFields,
      InputElementOptions,
      DeviceSelector,
      Heading,
      Accordion,
      Font,
      BackgroundAndBorder,
      BasePane,
      Dropdown,
      RangeInput,
      Align,
      Margin,
      Hide,
    },
    mixins: [itemMixin],
    data() {
      return {
        inputs: [],
        canEditMobile: true,
      };
    },
    computed: {
      ...mapState(['mobilePreview', 'selectedElement']),
      hasOptions() {
        return ['dropdown'].includes(this.selectedElementType);
      },
      inputValue: {
        get() {
          const name = this.getValueOf('data.form.customSettings.name');
          const customId = this.getValueOf('data.form.customSettings.customId');
          return { name, customId };
        },
        set(v) {
          this.$bus.$emit('switchInputElement', { customField: v });
        },
      },
      width: {
        get() {
          const mobileValue = this.getValueOf('mobile.smartSize.width');
          if (mobileValue === null) {
            return Number(this.getValueOf('desktop.smartSize.width'));
          }

          return Number(this.getValueOf('$device.smartSize.width'));
        },
        set(v) {
          this.setValueOf('$device.smartSize.width', v);
        },
      },
      height: {
        get() {
          const mobileValue = this.getValueOf('mobile.height');
          if (mobileValue === null) {
            return Number(this.getValueOf('desktop.height'));
          }

          return Number(this.getValueOf('$device.height'));
        },
        set(v) {
          this.setValueOf('$device.height', v);
        },
      },
      align: {
        get() {
          return this.getValueOf('$device.justifyContent');
        },
        set(value) {
          this.setValueOf('$device.justifyContent', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      allowBlock: {
        get() {
          return this.smartGetValueOf('data.form.customSettings.allowOnlyNewLead');
        },
        set(v) {
          this.smartSetValueOf('data.form.customSettings.allowOnlyNewLead', v);
        },
      },
      allowBlockErrorMessage: {
        get() {
          return this.smartGetValueOf('data.form.customSettings.allowBlockCustomErrorMessage');
        },
        set(v) {
          this.smartSetValueOf('data.form.customSettings.allowBlockCustomErrorMessage', v);
        },
      },
      selectedElementType() {
        return this.getValueOf('data.form.customSettings.type');
      },
      isEmailAndHasBlockEmailFlag() {
        return this.selectedElementType === 'email';
      },
      widthOptions() {
        const opts = [{ value: 'manual', text: this.$t('manual') }];

        if (!this.isNano) {
          opts.push({ value: '100w', text: this.$t('simple100') });
        }

        if (this.mobilePreview) {
          opts.unshift({ value: null, text: this.$t('default') });
        }

        return opts;
      },
      widthType: {
        get() {
          return this.getValueOf('$device.smartSize.type');
        },
        set(value) {
          this.setValueOf('$device.smartSize.type', value);
        },
      },
    },
    watch: {
      async selectedElementType() {
        this.setMatchingInputs();
      },
    },
    mounted() {
      this.getInputs();

      // region Manage inputs ON listener
      this.$bus.$on('switchInputElement', this.getInputs);
      // endregion
    },

    beforeDestroy() {
      // region Manage inputs OFF listener
      this.$bus.$off('switchInputElement', this.getInputs);
      // endregion
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      ...mapActions(['getMatchingInputs']),
      manageFields() {
        this.changeFormManagerVisibility({ show: 'manageField', fields: this.inputs });
      },
      createNewInput() {
        this.changeFormManagerVisibility({ show: 'secondStep' });
      },
      inputValidation() {
        this.changeFormManagerVisibility({ show: 'inputValidations' });
      },
      async getInputs() {
        this.inputs = await this.getMatchingInputs();
      },
      async setMatchingInputs() {
        this.matchingInputs = await this.getMatchingInputs();
      },
    },
  };
</script>
